import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/Image';
import lokacija from './lokacija.png';
import Testimonial from '../testimonial/Testimonial';
import UslugeMin from '../usluge-min/UslugeMin';


export default function KontaktiBody() {
    return (
        <div>
            <Container>
                <Row>
                    <h1>Konataki</h1>
                </Row>
                <Row>
                    <Col sm={4}>
                        <p>Nalazimo se na adresi "Kralja Aleksandra 23" Sabac</p>
                        <p>Prvi lokal posle katastra</p>
                        <p>Detaljniju lokaciju mozete videti na mapi ili samo ukucajte koordinate u mapi 44.760465, 19.697845</p>
                    </Col>
                    <Col sm={4}>
                        <p>GeoMap 015</p>
                        <p>Sediste firme:</p>
                        <p>Kralja Aleksandra 23, 15000 Sabac</p>
                        <p>Tel: 066/017-122</p>
                        <p>Mail: geomap015@gmail.com</p>
                    </Col>
                    <Col sm={4}>
                        <Image src= {lokacija} fluid />
                    </Col>
                </Row>
                <Row>
                <Row>
                    <Col sm={12}>
                        <Testimonial />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <UslugeMin />
                    </Col>
                </Row>
                </Row>
            </Container>
        </div>
    )
}
