import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <div className="footer-txt">
            <p>Copyright C 2020 GeoMap015</p>
        </div>
    )
}
