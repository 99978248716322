import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import './UslugeMin.css';
import Image from 'react-bootstrap/Image';
import {Link} from 'react-router-dom';

import i1 from '../index_body/carousel/sliderpics/galerija/g1.jpg';
import i2 from '../index_body/carousel/sliderpics/galerija/g2.jpg';
import i3 from '../index_body/carousel/sliderpics/galerija/g3.jpg';
import i4 from '../index_body/carousel/sliderpics/galerija/g4.jpg';
import i5 from '../index_body/carousel/sliderpics/galerija/g5.jpg';
import i6 from '../index_body/carousel/sliderpics/galerija/g6.jpg';

export default function UslugeMin() {
    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <h4>Izdvajamo iz ponude</h4>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4}>
                        <ul className="uslugeMinStyle">
                        <Link to="/omedjavanje"><li>Obnova granicnih linija parcela- omedjavanje</li></Link>
                        <Link to="/deobaparcela"><li>Deoba parcela</li></Link>
                        <Link to="/ucrtavanjeobjekata"><li>Ucrtavanje objekata</li></Link>
                        <Link to="/ozakonjenjeobjekata"><li>Ozakonjenje objekata</li></Link>
                        <Link to="/geodetskepodloge"><li>Izrada geodetskih podloga</li></Link>
                        </ul>
                    </Col>
                    <Col sm={4}>
                        <ul className="uslugeMinStyle">
                        <Link to="/podzemeninstalacije"><li>Snimanje podzeminh instalacija</li></Link>
                        <Link to="/aerosnimanje"><li>Aero snimanje</li></Link>
                        <Link to="/saobracajnice"><li>Snimanje saobracajnica</li></Link>
                        <Link to="/listovinepokretnosti"><li>Izdavanje posedovnih listova i kopija planova</li></Link>
                        <Link to="/inzenjerskageodezija"><li>Inzenjerska geodezija</li></Link>
                        </ul>
                    </Col>
                    <Col sm={4}>
                        <Row>
                        
                        <Col><Link to="/galerija"><Image src= {i1} thumbnail /></Link></Col>
                        <Col><Link to="/galerija"><Image src= {i2} thumbnail /></Link></Col>
                        <Col><Link to="/galerija"><Image src= {i3} thumbnail /></Link></Col>
                        </Row>
                        <Row>
                        <Col><Link to="/galerija"><Image src= {i4} thumbnail /></Link></Col>
                        <Col><Link to="/galerija"><Image src= {i5} thumbnail /></Link></Col>
                        <Col><Link to="/galerija"><Image src= {i6} thumbnail /></Link></Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
