import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import i1 from '../index_body/carousel/sliderpics/7.jpg';
import Image from 'react-bootstrap/Image';
import Testimonial from '../testimonial/Testimonial';
import UslugeMin from '../usluge-min/UslugeMin';

export default function Omedjavanje() {
    return (
        <div>
            <Container>
                <Row>
                    <Col sm={12}>
                        <h1>Usluge-Omedjavanje</h1>
                        <hr className="hr-white" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={9}>
                        <Image src={i1} fluid />
                        <hr className="hr-white" />
                        <p>U koliko su Vam medje unistene ili sumnjate da Vam medja nije dobra ili iz bilo kog razloga zelite da obnovite granicne tacke svoje parcele, mi Vam mozemo pomoci. Na efikasan nacin povraticemo tacke Vase parcele na obostrano zadovoljstvo</p>

                    </Col>
                    <Col sm={3}>
                        <p>Kontakti:</p>
                        <p>GeoMap 015</p>
                        <hr className="hr-white" />
                        <p>Adresa: Kralja Aleksandra 23, 15000 Sabac</p>
                        <hr className="hr-white" />
                        <p>Tel: 066/017-122</p>
                        <hr className="hr-white" />
                        <p>Mail: geomap015@gmail.com</p>
                        <hr className="hr-white" />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Testimonial />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <UslugeMin />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
