import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Navbar.scss';
import { Link } from 'react-router-dom'

class Header extends Component {
    state = { clicked: false }

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Navbar.Brand as={Link} to="/">GeoMap 015</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        
                    </Nav>
                    <Nav>
                    <Nav.Link as={Link} to="/">Pocetna</Nav.Link>
                    <Nav.Link as={Link} to="/onama">O Nama</Nav.Link>
                        <NavDropdown title="Usluge" id="collasible-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/omedjavanje">Omedjavanje</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/deobaparcela">Deoba Parcela</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/ucrtavanjeobjekata">Ucrtavanje Objekata</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/ozakonjenjeobjekata">Ozakonjenje Objekata</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/geodetskepodloge">Izrada Geodetskih Podloga</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/podzemeninstalacije">Snimanje Podzemnih Instalacija</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/aerosnimanje">Aero Snimanje</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/saobracajnice">Snimanje Saobracajnica</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/listovinepokretnosti">Listovi Nepokretnosti I Kopije Planova</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/inzenjerskageodezija">Inzenjerska Geodezija</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/galerija">Galerija</Nav.Link>
                        <Nav.Link as={Link} to="/kontakti">Kontakti</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Header