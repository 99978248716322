import React from 'react';
import './Testimonial.css';
import Avatar from 'react-avatar';

export default function Testimonial() {
    return (

        <div className="testimonials">
        <hr className="hr-white" />
        <h3>Zadovoljni Klijenti</h3>
            <div className="inner">
                <div className="row">
                    <div className="col">
                        <div className="testimonial">
                            <Avatar name="Petar Bozic" size="65" round={true} />
                            <div className="name">Petar Bozic</div>
                            <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>

                            <p>
                                Brza i efikasna usluga, sve svoje probleme i usluge sam resio jednim telefonskim pozivom.
              </p>
                        </div>
                    </div>

                    <div className="col">
                        <div className="testimonial">
                            <Avatar name="Stefan Srdanovic" size="65" round={true} />
                            <div className="name">Stefan Srdanovic</div>
                            <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="far fa-star"></i>
                            </div>

                            <p>
                                Mlada i odabrana ekipa efikasno resava administrativne poslove.
              </p>
                        </div>
                    </div>

                    <div className="col">
                        <div className="testimonial">
                            <Avatar name="Milena Nenadovic" size="65" round={true} />
                            <div className="name">Milena Nenadovic-Smiljanic</div>
                            <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>

                            <p>
                                Sve pohvale za profesionalno odradjen posao na terenu, omedjavanje je izvedeno profesionalno.
              </p>
                        </div>
                    </div>

                </div>


            </div>
            <hr className="hr-white" />
        </div>
    )
}
