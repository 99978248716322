import ImageGallery from 'react-image-gallery';
import React from 'react';
import './GalerijaBody.css';

import i1 from '../index_body/carousel/sliderpics/galerija/g1.jpg';
import i2 from '../index_body/carousel/sliderpics/galerija/g2.jpg';
import i3 from '../index_body/carousel/sliderpics/galerija/g3.jpg';
import i4 from '../index_body/carousel/sliderpics/galerija/g4.jpg';
import i5 from '../index_body/carousel/sliderpics/galerija/g5.jpg';
import i6 from '../index_body/carousel/sliderpics/galerija/g6.jpg';
import i7 from '../index_body/carousel/sliderpics/galerija/g7.jpg';


const images = [
  {
    original: i1,
    thumbnail: i1,
  },
  {
    original: i2,
    thumbnail: i2,
  },
  {
    original: i3,
    thumbnail: i3,
  },
  {
    original: i4,
    thumbnail: i4,
  },
  {
    original: i5,
    thumbnail: i5,
  },
  {
    original: i6,
    thumbnail: i6,
  },
  {
    original: i7,
    thumbnail: i7,
  }
];


class  GalerijaBody extends React.Component {
    render() {
      return <ImageGallery items={images} />;
    }
  }

  export default GalerijaBody